.App {

}

.Head {

}

.Nav {
    display:flex;
    justify-content: space-between;
    padding:20px;
}

.Current {
    font-size: 18px;
    text-align: center;
}
.ToggleContainer {
    text-align: center;
    margin-bottom:4px;
}
.Preview {
    display: flex;
}
.PreviewFrame {
    margin: 15px;
    width: 100%;
    height: calc(100vh - 180px);
}
iframe {
    width:100%;
    height:100%;
}